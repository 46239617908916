// https://stackoverflow.com/questions/56663785/invalid-hook-call-hooks-can-only-be-called-inside-of-the-body-of-a-function-com
// https://reactjs.org/warnings/invalid-hook-call-warning.html#duplicate-react
// https://stackoverflow.com/questions/61800182/how-to-route-angular-app-inside-nginx-container

import {useState, useEffect} from 'react';

import { IonApp, IonRouterOutlet, IonSplitPane
} from '@ionic/react';
import Home from './pages/Home';


import {useStorage,ProtectedRoute} from 'irc-common';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import magimeta from './magi-meta.json'

import React from 'react';



interface Json {[key:string]:any};


const App: React.FC = () => {

  const [data,setData] = useStorage("data");

  const [entities,setEntities] = useState<Json>({});

  const [session,setSession] = useState<Json>({})


  return (
    <IonApp>
      <Home></Home>
    </IonApp>
  );
};

export default App;
