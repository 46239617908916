import { useState, useEffect } from "react";


import axios from 'axios';



import config from "../config.json";

axios.defaults.withCredentials = true;


const BASE_URL = config["api.endport"]

const LOGIN_URL = BASE_URL + "/v1/user/login";
const LOGOUT_URL = BASE_URL + "/v1/user/logout";
const UNREGISTER_URL = BASE_URL + "/v1/user/unregister";


interface Json {[key:string]:any};


interface RpcReq{
  method:string, 
  args:{[key:string]:object},
}

export const restCall = (res:string,method:string, params?:Json, args?:Json ,
  scb?:(args:any)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  let config:Json = {
    method:method,
    baseURL:BASE_URL,
    url: res.startsWith('/')?res: '/rest/' + res,
    withCredentials:true
  }

  if (params){
    config['params'] = params;
  }
  if (args){
    config['data'] = args;
  }

  axios(config).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}

export const login = ( args:Json,
  scb:(args:Json)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  axios( {url: LOGIN_URL,params:args}).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}


export const logout = (scb:(args:Json)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{



  axios({url:LOGOUT_URL, params:{}}).then(response => {


    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}


export const unregister = ( scb:(args:Json)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  axios.delete( UNREGISTER_URL,{withCredentials:true}).then(response => {


    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}


export const useRestGet = (res:string)=>{
  const [item,setItem] = useState<Json>({});

  const getItem = (params?:Json)=>{
    restCall(res,'get',params,null,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItem(resp);
    })
  }

  let ret:[Json,(args?:Json)=>void] = [item,getItem]
  

  return ret;  
}


