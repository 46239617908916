import {useEffect,useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow ,IonButton,IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
  IonItem,
  useIonToast,
  IonModal,
  IonIcon} from '@ionic/react';
import { useParams } from 'react-router';


import '../common/styles.css';

import styles from './Home.module.css';

import {useHistory, useLocation} from 'react-router';
import {useRestGet,logout} from '../hooks/UserHttp';
import { logOutOutline, personOutline, trashOutline } from 'ionicons/icons';
import { Json } from '../common/types';

import LoginForm from '../modal/LoginForm';
import UnregisterForm from '../modal/UnregisterForm';

const Home: React.FC = () => {

  
  const [state,setState] = useState<Json>({})

  const [toastPresent,toastDismiss] = useIonToast();

  useEffect(()=>{




  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonMenuButton slot="start"/>
          <IonTitle>首页</IonTitle>
          { state.login &&
          <IonButtons slot="end">
          <IonButton >
              <IonIcon slot="start" icon={personOutline} />
              <IonLabel>{ state.data.email}[{state.data.role}]</IonLabel>
            </IonButton>
            

            <IonButton  onClick={()=>{
              logout(d=>{
                
                  console.log('logout success');
                  
                  setState({});

                
              });
            }}>
              <IonIcon slot="start" icon={logOutOutline} />
              <IonLabel>登出</IonLabel>
            </IonButton>
          </IonButtons>
          }
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>


      {
        state.login &&

        <IonCard>
        <IonCardSubtitle className={styles.cardTitle}>用户信息</IonCardSubtitle>
          <IonCardContent>
          
            <IonGrid className={styles.homeGrid}>
              <IonRow className={styles.homeRow}>

                <IonCol size="5" className={styles.homeCol}>
                  用户账号
                </IonCol>
                <IonCol size="5">
                  {state.data.email || state.data.mobile}
                </IonCol>
              </IonRow>

              <IonRow className={styles.homeRow}>

                <IonCol size="5" className={styles.homeCol}>
                  注册日期
                </IonCol>
                <IonCol size="5">
                  {state.data.created_date }
                </IonCol>
              </IonRow>


              <IonRow className={styles.homeRow}>
                <IonCol size="5" className={styles.homeCol}>
                  
                </IonCol>

                <IonCol size="5">
                  <IonButton  fill='outline' color='danger' onClick={()=>{
                  
                    setState({...state,unregister:true});
                  }}>
                  <IonIcon slot="start" icon={trashOutline} />
                  <IonLabel >删除账号及数据</IonLabel>
                </IonButton>
                </IonCol>
              </IonRow>

              
            </IonGrid>
          </IonCardContent>
        </IonCard>
      }

        <IonModal isOpen={!state.login }>
          <LoginForm scb={(d:Json)=>{
            setState({...state, login:true , data:d});
          }} fcb={
            (e:string)=>{
              toastPresent(e, 3000);
            }
          } />
        </IonModal>


        {
          state.login &&
          <IonModal isOpen={state.unregister }>
          <UnregisterForm account={state.data.email || state.data.mobile} scb={(d:Json)=>{
            setState({...state, login:false , unregister:false});
          }} fcb={
            (e:string)=>{
              toastPresent(e, 3000);
            }
          } />
        </IonModal>
        }
        

      </IonContent>
    </IonPage>
  );
};

export default Home;
