import {useEffect, useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonLabel,IonInput,IonButton,
  useIonToast, useIonLoading} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';


import {AppContext} from '../global';
  
import {login} from '../hooks/UserHttp';

import styles from '../common/styles.module.css';

import {useHistory, useLocation} from 'react-router';


interface Json {[key:string]:any};


const LoginForm: React.FC<{
  scb:any,
  fcb:any
}> = ({scb,fcb}) => {
  const [toastPresent,toastDismiss] = useIonToast();

  const [state,setState] = useState<Json>({});

  const [present, dismiss] = useIonLoading();


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>登录</IonTitle>
        </IonToolbar>
      </IonHeader>

      <>
      <IonContent fullscreen>
        <IonItem>
          <IonLabel position="floating">邮箱</IonLabel>
          <IonInput value={state.email} onIonChange={e => setState({...state,email: e.detail.value})}></IonInput>
        </IonItem>
       <IonItem>
          <IonLabel position="floating">密码</IonLabel>
          <IonInput value={state.passwd} onIonChange={e => setState({...state,passwd: e.detail.value})} type='password'></IonInput>
        </IonItem>

      </IonContent>
      <IonItem className={styles.bottom_bar} lines="none">
          <IonButton slot="end"  size="default" color="secondary" expand="block" fill="solid"
            onClick={()=>{

              present().then(() =>{
                login({email:state.email,passwd:state.passwd},d=>{

                  console.log('login success:' + JSON.stringify(d));
  
                  dismiss().then(()=>{
                    
                    scb(d);
                  });
  
  
                },e=>{
  
                  dismiss().then(()=>{
                    
                    fcb(e);
                  });
  
                });
              });

              
           
          }} >确认</IonButton>
      </IonItem>
      </>

    </IonPage>
  );
};

export default LoginForm;
