import {useEffect, useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonLabel,IonInput,IonButton,
  useIonToast, useIonLoading} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';


import {AppContext} from '../global';
  
import {unregister} from '../hooks/UserHttp';

import styles from '../common/styles.module.css';



interface Json {[key:string]:any};


const UnregisterForm: React.FC<{
  account:string,
  scb:any,
  fcb:any
}> = ({account, scb,fcb}) => {
  const [toastPresent,toastDismiss] = useIonToast();

  const [state,setState] = useState<Json>({});

  const [present, dismiss] = useIonLoading();

   

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>删除账号</IonTitle>
        </IonToolbar>
      </IonHeader>

      <>
      <IonContent fullscreen>
        <IonItem>
          <IonLabel position="stacked">即将下列删除账号</IonLabel>
          <IonInput value={ account} disabled ></IonInput>
        </IonItem>
       
        <IonItem>
          <IonLabel position="floating">请输入[确认删除账号及该账号的相关数据]</IonLabel>
          <IonInput value={state.confirm} onIonChange={e => setState({...state,confirm: e.detail.value})} ></IonInput>
        </IonItem>

      </IonContent>
      <IonItem className={styles.bottom_bar} lines="none">
          <IonButton slot="end"  size="default" color="danger" expand="block" fill="solid" 
            onClick={()=>{

              if(state.confirm !== '确认删除账号及该账号的相关数据') {
                toastPresent('请输入[确认删除账号及该账号的相关数据]以确认该操作！',3000);
                return;
              }

              present();

              unregister(d=>{

                dismiss();

                scb(d);

              },e=>{

                dismiss();

                fcb(e);
              });
           
          }} >确认删除</IonButton>
      </IonItem>
      </>

    </IonPage>
  );
};

export default UnregisterForm;
